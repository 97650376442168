<template>
  <div>
    <div class="mb-6">
      <a
        class="text-decoration-none text-subtitle-2 font-weight-bold"
        @click.prevent="returnBack"
      >
        <v-icon
          color="primary"
          dense
          class="mr-2"
        >
          {{ icons.mdiArrowLeft }}
        </v-icon>
        Kembali
      </a>
    </div>
    <v-card
      :loading="isLoadingCard"
      class="mb-6"
    >
      <v-card-title>
        <h2>
          Informasi Sekolah
        </h2>
      </v-card-title>
      <v-card-text>
        <v-row class="align-center">
          <v-col
            md="6"
            cols="12"
          >
            <div class="d-flex align-center">
              <div class="me-4">
                <v-avatar
                  class="primary--text"
                  color="#EC008C24"
                >
                  1
                </v-avatar>
              </div>
              <div>
                <h2>{{ school.name }}</h2>
                <span>ID Sekolah : <span v-if="school.npsn">{{ school.npsn }}</span> <span v-else> - </span></span>
              </div>
            </div>
          </v-col>
          <v-col
            md="6"
            cols="12"
          >
            <div class="d-flex align-center">
              <div class="me-4">
                <v-avatar color="#EC008C24">
                  <v-container class="align-center">
                    <v-img
                      src="@/assets/icons/location.svg"
                      width="20"
                    ></v-img>
                  </v-container>
                </v-avatar>
              </div>
              <div>
                <h2>{{ school.place.address }}  </h2>
                <span v-if="school.place">{{ school.place.regencies.name }} , {{ school.place.district.name }}  </span>
                <span v-else>-</span>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-row class="match-height">
      <v-col
        md="6"
        cols="12"
      >
        <v-card :loading="isLoadingCard">
          <v-card-title>
            <h4>Library</h4>
            <v-spacer></v-spacer>
            <v-btn
              icon
              small
              class="me-n3 mt-n2"
            >
              <v-icon>
                {{ icons.mdiDotsVertical }}
              </v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text class="pa-4">
            <v-row>
              <v-col
                v-for="library in libraries"
                :key="library.name"
                md="4"
                cols="4"
              >
                <v-row>
                  <v-col
                    md="5"
                    cols="12"
                  >
                    <v-card :color="library.color">
                      <v-container>
                        <v-img :src="library.icon"></v-img>
                      </v-container>
                    </v-card>
                  </v-col>
                  <v-col
                    md="7"
                    class="pt-0 pa-lg-3"
                  >
                    <p class="mb-0">
                      {{ library.title }}
                    </p>
                    <p class="font-weight-bold text--black mb-0">
                      {{ library.total }}
                      <span
                        v-if="library.ratio >= 0"
                        class="font-weight-light text-sm success--text mb-0 "
                      >
                        {{ "+" + library.ratio }}
                      </span>

                      <span
                        v-else
                        class="font-weight-light text-sm error--text mb-0 "
                      >- {{ library.ratio }}
                      </span>
                    </p>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        md="6"
        cols="12"
      >
        <v-card :loading="isLoadingCard">
          <v-card-title>
            <h4>E-Learning</h4>
            <v-spacer></v-spacer>
            <v-btn
              icon
              small
              class="me-n3 mt-n2"
            >
              <v-icon>
                {{ icons.mdiDotsVertical }}
              </v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text class="pa-4">
            <v-row>
              <v-col
                v-for="elearning in elearnings"
                :key="elearning.title"
                md="4"
              >
                <v-row>
                  <v-col md="5">
                    <v-card :color="elearning.color">
                      <v-container>
                        <v-img :src="elearning.icon"></v-img>
                      </v-container>
                    </v-card>
                  </v-col>
                  <v-col
                    md="7"
                    class="pt-0 pa-lg-3"
                  >
                    <span>{{ elearning.title }}</span>
                    <p class="font-weight-bold text--black mb-0">
                      {{ elearning.total }}
                      <span
                        v-if="elearning.ratio > 0"
                        class="font-weight-light text-sm success--text mb-0 "
                      >
                        {{ "+"+elearning.ratio }}
                      </span>

                      <span
                        v-else
                        class="font-weight-light text-sm error--text mb-0 "
                      >
                        {{ elearning.ratio }}
                      </span>
                    </p>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        v-for="menu in menus"
        :key="menu.title"
        md="2"
        cols="12"
      >
        <v-card :loading="isLoadingCard">
          <v-card-title>
            <v-avatar :color="menu.color">
              <v-container class="d-flex align-center">
                <v-img :src="menu.icon"></v-img>
              </v-container>
            </v-avatar>
            <v-spacer></v-spacer>
            <v-btn
              icon
              small
              class="me-n3 mt-n2"
            >
              <v-icon>
                {{ icons.mdiDotsVertical }}
              </v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <p class="text-sm font-weight-bold mb-2">
              {{ menu.title }}
            </p>
            <p class="text-xl font-weight-black mb-2">
              {{ menu.total }}
              <span
                v-if="menu.ratio > 0"
                class="font-weight-light text-sm success--text mb-0 "
              >
                {{ "+"+menu.ratio }}
              </span>

              <span
                v-else
                class="font-weight-light text-sm error--text mb-0 "
              >
                {{ menu.ratio }}
              </span>
            </p>
            <span>Total {{ menu.title }}</span>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="match-height">
      <v-col
        md="8"
        cols="12"
      >
        <v-card :loading="isLoadingCard">
          <v-card-title class="d-flex">
            <h4>Aktifitas Penggunaan Aplikasi</h4>
            <v-spacer></v-spacer>
            <v-combobox
              label="Tahun"
              outlined
              :items="yearActivities"
              dense
              class="comboboxHidden"
            ></v-combobox>
            <v-btn
              icon
              small
              class="me-n3 mt-n2 ms-2"
            >
              <v-icon>
                {{ icons.mdiDotsVertical }}
              </v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <vue-apex-charts
              id="total-profit-chart"
              height="320"
              :options="chartOptionsApplication"
              :series="chartDataApplication"
            ></vue-apex-charts>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        md="4"
        cols="12"
      >
        <v-card :loading="isLoadingCard">
          <v-card-title>
            <h4>Pembelian Buku</h4>
            <v-spacer></v-spacer>
            <v-btn
              icon
              small
              class="me-n3 mt-n2"
            >
              <v-icon>
                {{ icons.mdiDotsVertical }}
              </v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <div
              v-for="bookPurchase in bookPurchases"
              :key="bookPurchase.uuid"
              class="mb-2"
            >
              <div class="d-flex justify-space-between align-center">
                <div>
                  <h4>Pembelian ke {{ bookPurchase.index }}</h4>
                  <span>{{ dateFormat(bookPurchase.created_at) }}</span>
                </div>
                <div class="text-center">
                  <h5>{{ bookPurchase.purchased_book }}</h5>
                  <span>Buku</span>
                </div>
              </div>
            </div>
          </v-card-text>
          <v-card-actions class="justify-center">
            <router-link
              :to="{name: 'most-active-school-detail-book-purchase'}"
              class="text-decoration-none"
            >
              Lihat Semua
            </router-link>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col md="5">
        <v-card :loading="isLoadingCard">
          <v-card-title>
            <h4>Guru Paling Aktif</h4>
            <v-spacer></v-spacer>
            <v-btn
              icon
              small
              class="me-n3 mt-n2"
            >
              <v-icon>
                {{ icons.mdiDotsVertical }}
              </v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
          </v-card-text>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th
                    class="text-left"
                  >
                    Nama
                  </th>
                  <th class="text-left">
                    Bank Soal
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(activeTeacher,index) in activeTeachers"
                  :key="index"
                >
                  <td class="py-2 d-flex align-center">
                    <v-avatar>
                      <v-img
                        v-if="activeTeacher.photo"
                        :src="activeTeacher"
                        width="40"
                      ></v-img>
                      <v-img
                        else
                        src="@/assets/images/avatars/ari.png"
                        width="40"
                      ></v-img>
                    </v-avatar>
                    <span
                      class="font-weight-light ms-4 text-truncate"
                      style="max-width: 150px"
                    >{{ activeTeacher.name }}</span>
                    <span class="font-weight-light text-truncate">{{ activeTeacher.school.name }}</span>
                  </td>
                  <!-- <td>20</td> -->

                  <td>{{ activeTeacher.question_bank_count }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-card-actions class="justify-center">
            <router-link
              :to="{name: 'most-active-school-detail-active-teacher'}"
              class="text-decoration-none"
            >
              Lihat Semua
            </router-link>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col md="7">
        <v-card :loading="isLoadingCard">
          <v-card-title>
            <h4>Siswa Paling Aktif</h4>
            <v-spacer></v-spacer>
            <v-btn
              icon
              small
              class="me-n3 mt-n2"
            >
              <v-icon>
                {{ icons.mdiDotsVertical }}
              </v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
          </v-card-text>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th
                    class="text-left"
                  >
                    Nama
                  </th>
                  <th class="text-left">
                    Buku Dipinjam
                  </th>
                  <th class="text-left">
                    Aktifitas
                  </th>
                  <th class="text-left">
                    CBT
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(activeStudent,index) in activeStudents"
                  :key="index"
                >
                  <td class="py-2 d-flex align-center">
                    <v-avatar>
                      <v-img
                        v-if="activeStudent.photo"
                        :src="activeStudent"
                        width="40"
                      ></v-img>
                      <v-img
                        else
                        src="@/assets/images/avatars/ari.png"
                        width="40"
                      ></v-img>
                    </v-avatar>
                    <span
                      class="font-weight-light ms-4 text-truncate"
                      style="max-width: 150px"
                    >{{ activeStudent.name }}</span>
                  </td>
                  <td>{{ activeStudent.book_borrowed }}</td>
                  <td>{{ activeStudent.activity }}</td>
                  <td>54</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-card-actions class="justify-center">
            <router-link
              :to="{name: 'most-active-school-detail-active-student'}"
              class="text-decoration-none"
            >
              Lihat Semua
            </router-link>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col md="8">
        <v-card :loading="isLoadingCard">
          <v-card-title class="d-flex align-center">
            <h4>Aktifitas Sekolah</h4>
            <v-spacer></v-spacer>
            <v-combobox
              label="Tahun"
              outlined
              dense
              :items="yearActivities"
              class="comboboxHidden"
            ></v-combobox>
            <v-btn
              icon
              small
              class="me-n3 mt-n2 ms-2"
            >
              <v-icon>
                {{ icons.mdiDotsVertical }}
              </v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <vue-apex-charts
              id="total-profit-chart"
              height="320"
              :options="chartOptionsSchool"
              :series="chartDataSchool"
            ></vue-apex-charts>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import iconBook from '@/assets/icons/white/book.svg'
import iconDocumentText from '@/assets/icons/white/document-text.svg'
import iconMessageText from '@/assets/icons/white/message-text.svg'
import iconMessages from '@/assets/icons/white/messages-2.svg'
import iconMonitor from '@/assets/icons/white/monitor.svg'
import iconMusic from '@/assets/icons/white/music.svg'
import iconPlay from '@/assets/icons/white/play.svg'
import iconReceipt from '@/assets/icons/white/receipt-2.svg'
import iconSimcard from '@/assets/icons/white/simcard.svg'
import iconTaskSquare from '@/assets/icons/white/task-square.svg'
import iconUser from '@/assets/icons/white/user.svg'
import dateTimeFormat from '@/utils/date/dateTimeFormat'
import { getVuetify } from '@core/utils'
import { kFormatter } from '@core/utils/filter'
import { mdiArrowLeft, mdiDotsVertical } from '@mdi/js'
import VueApexCharts from 'vue-apexcharts'

export default {
  name: 'MostActiveSchoolDetail',
  components: {
    VueApexCharts,
  },
  data() {
    return {
      vuetify: getVuetify(),
      isLoadingCard: true,
      icons: {
        mdiDotsVertical,
        mdiArrowLeft,
        iconBook,
        iconMusic,
        iconPlay,
        iconMessages,
        iconDocumentText,
        iconMessageText,
        iconSimcard,
        iconUser,
        iconMonitor,
        iconReceipt,
        iconTaskSquare,
      },
      school: {
        place: {
          regencies: {},
          district: {},
        },
      },
      service: 'dashboardadmin',
      libraries: [
        {
          icon: iconBook,
          title: 'E-Book',
          total: 0,
          color: '#EC008C',
          keyName: 'count_ebook',
          keyRatioName: 'ratio_ebook',
        },
        {
          icon: iconMusic,
          title: 'Audio Book',
          total: 0,
          color: '#7D2B8B',
          keyName: 'count_audio',
          keyRatioName: 'ratio_audio',
        },
        {
          icon: iconPlay,
          title: 'Video Book',
          total: 0,
          color: '#149FE5',
          keyName: 'count_video',
          keyRatioName: 'ratio_video',
        },
      ],
      elearnings: [
        {
          icon: iconMessages,
          title: 'Pelajaran',
          total: 0,
          color: '#EC008C',
          keyName: 'count_lesson',
          keyRatioName: 'ratio_lesson',
        },
        {
          icon: iconDocumentText,
          title: 'Latihan',
          total: 0,
          color: '#FFB400',
          keyName: 'count_exercise',
          keyRatioName: 'ratio_exercise',
        },
        {
          icon: iconSimcard,
          title: 'Ujian',
          total: 0,
          color: '#56CA00',
          keyName: 'count_exam',
          keyRatioName: 'ratio_exam',
        },
      ],
      menus: [
        {
          icon: iconUser,
          title: 'Siswa',
          total: 0,
          ratio: 0,
          color: '#7D2B8B',
          keyName: 'count_student',
          keyRatioName: 'ratio_student',
        },
        {
          icon: iconUser,
          title: 'Guru',
          total: 0,
          ratio: 0,
          color: '#56CA00',
          keyName: 'count_teacher',
          keyRatioName: 'ratio_teacher',
        },
        {
          icon: iconUser,
          title: 'Pegawai',
          total: 0,
          ratio: 0,
          color: '#FF4C51',
          keyName: 'count_employee',
          keyRatioName: 'ratio_employee',
        },
        {
          icon: iconMonitor,
          title: 'CBT',
          total: 0,
          ratio: 0,
          color: '#FFB400',
          keyName: 'count_cbt',
          keyRatioName: 'ratio_cbt',
        },
        {
          icon: iconReceipt,
          title: 'Bank Soal',
          total: 0,
          ratio: 0,
          color: '#FF4C51',
          keyName: 'count_question_bank',
          keyRatioName: 'ratio_question_bank',
        },

        {
          icon: iconSimcard,
          title: 'Evoting',
          total: 0,
          ratio: 0,
          color: '#149FE5',
          keyName: 'count_evoting',
          keyRatioName: 'ratio_evoting',
        },
        {
          icon: iconTaskSquare,
          title: 'Jurusan',
          total: 0,
          ratio: 0,
          color: '#EC008C',
          keyName: 'count_major',
          keyRatioName: 'ratio_major',
        },
        {
          icon: iconMessageText,
          title: 'Berita',
          total: 0,
          ratio: 0,
          color: '#56CA00',
          keyName: 'count_news',
          keyRatioName: 'ratio_news',
        },
      ],
      chartOptionsSchool: {
        colors: ['#EC008C'],
        chart: {
          type: 'bar',
          stacked: true,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            columnWidth: '35%',
            startingShape: 'rounded',
            endingShape: 'rounded',
          },
        },
        xaxis: {
          categories: [],
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: {
          labels: {
            formatter: value => kFormatter(value, 0),
          },
        },
        grid: {
          strokeDashArray: 10,
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        stroke: {
          curve: 'smooth',
          width: 6,
          lineCap: 'round',
          colors: ['#fff'],
        },
        responsive: [
          {
            breakpoint: 1400,
            options: {
              plotOptions: {
                bar: {
                  borderRadius: 10,
                  columnWidth: '50%',
                },
              },
            },
          },
        ],
      },
      chartDataSchool: [
        {
          data: [
            {
              x: '',
              y: 0,
            },
          ],
        },
      ],
      chartOptionsApplication: {
        colors: ['#EC008C'],
        chart: {
          type: 'bar',
          stacked: true,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            columnWidth: '35%',
            startingShape: 'rounded',
            endingShape: 'rounded',
          },
        },
        xaxis: {
          categories: [],
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: {
          labels: {
            formatter: value => kFormatter(value, 0),
          },
        },
        grid: {
          strokeDashArray: 10,
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        stroke: {
          curve: 'smooth',
          width: 6,
          lineCap: 'round',
          colors: ['#fff'],
        },
        responsive: [
          {
            breakpoint: 1400,
            options: {
              plotOptions: {
                bar: {
                  borderRadius: 10,
                  columnWidth: '50%',
                },
              },
            },
          },
        ],
      },
      chartDataApplication: [
        {
          data: [
            {
              x: 'a',
              y: 1,
            },
          ],
        },
      ],
      activeTeachers: [],
      activeStudents: [],
      bookPurchases: [],
      yearActivities: [],
      yearAppActivities: [],
      school_uuid: '',
    }
  },
  mounted() {
    this.getActiveSchoolDetail(this.$route.params.uuid)
  },
  methods: {
    async getActiveSchoolDetail(uuid) {
      await this.$services.ApiServices.get(this.service, uuid).then(({ data }) => {
        this.school = data.data.school_information
        this.school_uuid = this.school.uuid
        this.activeStudents = data.data.active_students
        this.activeTeachers = data.data.active_teachers
        data.data.total_application_activities.forEach(el => this.yearAppActivities.push(el.year))
        data.data.school_activities.forEach(el => this.yearActivities.push(el.year))
        const chartDataApplication = [
          {
            data: [],
          },
        ]
        chartDataApplication[0].data = data.data.total_application_activities.map(el => ({
          x: el.month,
          y: el.total_activity,
        }))
        this.chartDataApplication = chartDataApplication

        const chartDataSchool = [
          {
            data: [],
          },
        ]
        chartDataSchool[0].data = data.data.school_activities.map(el => ({
          x: el.month,
          y: el.total_activity,
        }))
        this.chartDataSchool = chartDataSchool
        this.bookPurchases = data.data.book_purchased.library.map((bookPurchase, index) => ({
          ...bookPurchase,
          index: index + 1,
        }))

        Object.keys(data.data).forEach((key, item) => {
          this.libraries.forEach(el => {
            if (el.keyName === key) {
              el.total = data.data[key]
            }
            if (el.keyRatioName === key) {
              el.ratio = data.data[key]
            }
          })
        })
        Object.keys(data.data).forEach((key, item) => {
          this.menus.forEach(el => {
            if (el.keyName === key) {
              el.total = data.data[key]
            }
            if (el.keyRatioName === key) {
              el.ratio = data.data[key]
            }
          })
        })
        Object.keys(data.data).forEach((key, item) => {
          this.elearnings.forEach(el => {
            if (el.keyName === key) {
              el.total = data.data[key]
            }
            if (el.keyRatioName === key) {
              el.ratio = data.data[key]
            }
          })
        })
      })
      this.isLoadingCard = false
    },
    dateFormat(time) {
      return dateTimeFormat(time, '/')
    },
    returnBack() {
      this.$router.back()
    },
  },
}
</script>

<style>
.comboboxHidden > .v-input__control > .v-text-field__details {
  display: none;
}
</style>
